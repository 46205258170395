[
  {
    "startTime": 21.34529987220764,
    "lyric": "It's been about 10 years since we met",
    "endTime": 24.042530898910524
  },
  {
    "startTime": 24.042530898910524,
    "lyric": "Time’s flown by like a private jet",
    "endTime": 26.74331987411499
  },
  {
    "startTime": 26.74331987411499,
    "lyric": "As you pack your bags this week",
    "endTime": 29.347686965667723
  },
  {
    "startTime": 29.347686965667723,
    "lyric": "Make room for these memories",
    "endTime": 33.54538915830994
  },
  {
    "startTime": 52.54538915830994,
    "lyric": "Half a dozen trips to Yosemite",
    "endTime": 56.0441819923706
  },
  {
    "startTime": 56.0441819923706,
    "lyric": "Those days always left me feeling free",
    "endTime": 58.74778906103516
  },
  {
    "startTime": 58.74778906103516,
    "lyric": "Our legs hanging over cliff side views",
    "endTime": 61.446937870300296
  },
  {
    "startTime": 61.446937870300296,
    "lyric": "and feet aching in our dusty shoes  ",
    "endTime": 64.0460410858307
  },
  {
    "startTime": 64.0460410858307,
    "lyric": "But don’t forget about the other places",
    "endTime": 66.64282080354309
  },
  {
    "startTime": 66.64282080354309,
    "lyric": "Big Sur, Twin Peaks, Tamalpais",
    "endTime": 69.34851413160706
  },
  {
    "startTime": 69.34851413160706,
    "lyric": "Moro Rock hearing thunder roar",
    "endTime": 72.04539815830994
  },
  {
    "startTime": 72.04539815830994,
    "lyric": "Lake Tahoe everyone left sore",
    "endTime": 74.74396604005432
  },
  {
    "startTime": 74.74396604005432,
    "lyric": "What I’m trying to get at here Ali",
    "endTime": 77.3431208512268
  },
  {
    "startTime": 77.3431208512268,
    "lyric": "is your love of nature rubbed off on me",
    "endTime": 80.0445909923706
  },
  {
    "startTime": 80.0445909923706,
    "lyric": "and that part of me will always be you",
    "endTime": 82.743356874115
  },
  {
    "startTime": 82.743356874115,
    "lyric": "Stay awake, that part will stay with you",
    "endTime": 85.74607689509583
  },
  {
    "startTime": 106.74607689509583,
    "lyric": "Do you remember when we biked through Bethany?",
    "endTime": 109.3462787997284
  },
  {
    "startTime": 109.3462787997284,
    "lyric": "Me and you and Paul and Sappy",
    "endTime": 112.0460848474121
  },
  {
    "startTime": 112.0460848474121,
    "lyric": "You tried so hard to stay in control",
    "endTime": 114.74594789509582
  },
  {
    "startTime": 114.74594789509582,
    "lyric": "but you still steered right into a pole",
    "endTime": 117.34392394468689
  },
  {
    "startTime": 117.34392394468689,
    "lyric": "How about when we played Monopoly",
    "endTime": 120.04653284741211
  },
  {
    "startTime": 120.04653284741211,
    "lyric": "We stayed up at Sap's until like 3",
    "endTime": 122.64389389700317
  },
  {
    "startTime": 122.64389389700317,
    "lyric": "Got drunk for the first time with you two",
    "endTime": 125.34416001716613
  },
  {
    "startTime": 125.34416001716613,
    "lyric": "Bread tastes so good when lit, who knew?",
    "endTime": 128.0459548474121
  },
  {
    "startTime": 128.0459548474121,
    "lyric": "Next verse is for you and Timothy ",
    "endTime": 130.64376596948242
  },
  {
    "startTime": 130.64376596948242,
    "lyric": "You've both honestly inspired me",
    "endTime": 133.34634303814698
  },
  {
    "startTime": 133.34634303814698,
    "lyric": "Video, guitar, travel toasty",
    "endTime": 135.44599694277954
  },
  {
    "startTime": 135.44599694277954,
    "lyric": "Wish you the best in this next journey",
    "endTime": 138.04705701335143
  },
  {
    "startTime": 160.04705701335143,
    "lyric": "One more thing, listen before you go",
    "endTime": 162.64480513542176
  },
  {
    "startTime": 162.64480513542176,
    "lyric": "One last thought I thought you should know",
    "endTime": 165.3425710896454
  },
  {
    "startTime": 165.3425710896454,
    "lyric": "Love you Alice, I will miss you so much",
    "endTime": 168.14428613542177
  },
  {
    "startTime": 168.14428613542177,
    "lyric": "Let's do more than just keep in touch",
    "endTime": 171
  }
]
